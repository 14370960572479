import axios from "axios";
import Constants from "src/Constants";
import useSWR from "swr";

export type TOrderDetails = {
    code: number;
    reason: string;
    message: string;
    data: IShareData;
};

export interface IShareData {
    user_id: string;
    bu_order_id: string;
    bu_order_type: string;
    avatar: string;
    order_status: string;
    nick_name: string;
    order_create_time: number;
    order_end_time: number;
    order_data: {
        payload: {
            base: string;
            quote: string;
            bu_order_data: {
                top: number;
                bottom: number;
                row: number;
                grid_type: string;
                trend: string;
                open_price: number;
                init_price: number;
                init_quote_price: number;
                base_investment: number;
                quote_investment: number;
                grid_profit: number;
                total_profit: number;
                leverage: number;
                base_amount: number;
                quote_amount: number;
            };
        };
    };
    leverage: number;
    allow_parameter_hidden: boolean;
    fee_rate: number;
    shared_ration: number;
    unique_id: string;
    share_code: string;
    extra_data?: {
        share_code?: string;
        is_private?: number;
    };
}

export const useShareRatioData = ({ id }: { id: string }) => {
    return useSWR(
        id,
        (id) =>
            axios
                .get<TOrderDetails>(`${Constants.kolFHost}/trading_bot_order/?unique_id=${id}`)
                .then((res) => res.data)
                .then((res) => {
                    if (res.code === 0) {
                        return res.data;
                    } else {
                        throw res;
                    }
                }),
        {
            errorRetryCount: 3,
        },
    );
};
