import style from "./style.module.css";
import { useCallback, useMemo, useRef, useState } from "react";
import { $num, addPlus, gotoPionexApp, isMobile } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import { useTickerBatch } from "../FutureGrid/CurrentPrice/useTickerIndex";
import { calculateAnnualized } from "../FutureGrid/calculation";
import { ExchangeTicker, SafeDecimal } from "trade_utils_lib";
import { getLastDesc } from "src/components/OrderAnnualized";
import moment from "moment";
import { ExchangeMTGOrder, MTGCoinItem, TradeUtils } from "TradeAPILib";
import UserCard from "src/ShareUIComponentV2/UserCard";
import Tips from "src/ShareUIComponentV2/Tips";
import { ProfitCard } from "src/ShareUIComponentV2/ProfitCard";
import PionexLogo from "src/ShareUIComponentV2/PionexLogo";
import CodeCard from "src/ShareUIComponentV2/CodeCard";
import { useImage, useSymbolPrice } from "src/use";
import { ModalConfirm } from "src/components/Modal/index";
import { report } from "src/utils/report";
import { MartingaleShareProcessor } from "trade_lib_wrapper";

export const MTGGridView = ({ data, share_id }: { data; share_id: string }) => {
    const mobileDevice = isMobile();
    const modalRef = useRef<any>(null);

    const quote = data?.order_data?.payload?.quote ?? "USDT";
    const isMulti = data?.order_data?.payload?.bu_order_data?.is_multi;

    const tickerCoins = useMemo(() => {
        let _tickerCoins = data?.order_data?.payload?.bu_order_data?.portfolio?.map((item) => ({ base: item.base, quote })) ?? [];
        if (isMulti && !_tickerCoins?.find((item) => item.base === "BTC")) {
            _tickerCoins?.push({ base: "BTC", quote });
        }
        return _tickerCoins;
    }, [data?.order_data?.payload?.bu_order_data?.portfolio, isMulti, quote]);

    const { data: tickerResp } = useTickerBatch(tickerCoins);
    const tickerMap = useMemo(() => {
        const mmap = new Map();
        tickerResp?.data.forEach((item) => {
            mmap.set(`${item.base}/${quote}`, item.price);
        });
        return mmap;
    }, [quote, tickerResp?.data]);

    const exchangeOrder = useMemo(() => {
        const _exchangeOrder = TradeUtils.convertToExchangeOrder({
            ...data?.order_data?.payload,
            bu_order_type: data?.bu_order_type,
            bu_order_id: data?.bu_order_id,
            bu_order_data: {
                ...data?.order_data?.payload?.bu_order_data,
                create_time: data?.order_create_time,
                close_time: data?.order_end_time,
            },
        });
        const baseTickers = tickerResp?.data?.map((item) => ({ base: item.base, quote, latest: item.price })) ?? [];

        _exchangeOrder && TradeUtils.calcGridOrderProfit(_exchangeOrder, 0, undefined, baseTickers as ExchangeTicker[]);
        return _exchangeOrder;
    }, [
        data?.bu_order_id,
        data?.bu_order_type,
        data?.order_create_time,
        data?.order_data?.payload,
        data?.order_end_time,
        quote,
        tickerResp?.data,
    ]);
    const innerOrder = exchangeOrder && (TradeUtils.getInnerOrder(exchangeOrder) as ExchangeMTGOrder | undefined);

    const shareInfo = MartingaleShareProcessor.useShareCardInfo({ exchangeOrder, shareData: data, tickerMap, $st: str, useSymbolPrice });

    type CoinItem = { totalValue: SafeDecimal } & MTGCoinItem;
    const coins: CoinItem[] = useMemo(() => {
        if (!innerOrder?.coins) {
            return [];
        }
        return innerOrder.coins.map((item) => {
            return {
                ...item,
                // 当前的实时总价值
                totalValue: new SafeDecimal(item.currentBaseAmount).mul(new SafeDecimal(tickerMap.get(`${item.base}/${quote}`) ?? 0)),
            };
        });
    }, [innerOrder?.coins, quote, tickerMap]);

    const baseCoin = !isMulti ? coins[0].base : "BTC";
    // 开单时基准币价格
    useSymbolPrice(baseCoin, isMulti ? "USDT" : quote, data?.order_create_time);
    // 当前基准币价格

    const qrUrl = useMemo(() => `${Constants.shareHost}${window.location.pathname}`, []);
    const shareCode = data.extra_data?.share_code || data.share_code;
    const isPrivate = !!data.extra_data?.is_private;
    const webUrl = `${Constants.mainHost}/trade/Bot?sr_share_id=${share_id}${shareCode ? `&referral=${shareCode}` : ""}`;

    const closedTips: {
        visible: boolean;
        text?: string;
        confirmText?: string[];
    } = useMemo(() => {
        if (!exchangeOrder?.isRunning) {
            return {
                visible: true,
                text: str("grid_futures_order_closed"),
            };
        }
        if (innerOrder?.isConditionAndNotTriggered) {
            return {
                visible: true,
                text: str("share_not_triggered_tips"),
            };
        }
        return {
            visible: false,
            text: undefined,
        };
    }, [exchangeOrder?.isRunning, innerOrder?.isConditionAndNotTriggered]);

    const [submitting, setSubmittingState] = useState(false);
    const onCreateOrder = useCallback(() => {
        report("mtg_gridbot_share_follow", { share_id });
        setSubmittingState(true);
        setTimeout(() => setSubmittingState(false), 3000);
        const lang = new URLSearchParams(window.location.search).get("l") || "zh-TW";
        const mUrl = `https://download.pionex.com/?lang=${lang}`;
        gotoPionexApp({
            url: webUrl,
            mUrl,
            page: "TRADE.bot.botOrderList",
            bot_share_id: share_id,
            referral: shareCode,
        });
    }, [shareCode, share_id, webUrl]);

    const onCreateOrderProxy = useCallback(() => {
        if (submitting) {
            return;
        }
        // 现货马丁暂未支持web跟单
        if (!mobileDevice) {
            modalRef.current?.show({
                title: str("confirm_follow_order_closed_title"),
                content: <div className={style.pionexModalContanerContent}>{str("confirm_follow_order_web_unsupported")}</div>,
                onConfirm() {},
                confirmText: str("modal_confirm_text"),
                cancelText: str("modal_cancel_text"),
            });
            return;
        }
        if (closedTips.visible && closedTips.confirmText) {
            modalRef.current?.show({
                title: str("confirm_follow_order_closed_title"),
                content: (
                    <div className={style.pionexModalContanerContent}>
                        {closedTips.confirmText?.map((text) => <div key={text}>{text}</div>)}
                    </div>
                ),
                onConfirm() {
                    onCreateOrder();
                },
                confirmText: str("confirm_follow_order_closed_title"),
            });
        } else {
            onCreateOrder();
        }
    }, [submitting, mobileDevice, closedTips.visible, closedTips.confirmText, onCreateOrder]);

    // 当前收益率
    const currentProfit = useMemo(() => {
        if (!innerOrder?.totalProfitPercent) {
            return "0";
        }

        return new SafeDecimal(innerOrder.totalProfitPercent).toDP(2, SafeDecimal.ROUND_FLOOR).valueOf();
    }, [innerOrder?.totalProfitPercent]);

    // 套利年化
    const annualizedGridProfit = useMemo(() => {
        const n = calculateAnnualized({
            createTime: exchangeOrder?.timestamp ?? 0,
            percentProfit: new SafeDecimal(innerOrder?.profit)
                .mul(100)
                .div(new SafeDecimal(innerOrder?.totalInvest))
                .valueOf(),
            endTime:
                exchangeOrder?.closeTimestamp && exchangeOrder?.closeTimestamp > 0 ? exchangeOrder?.closeTimestamp : new Date().getTime(),
        });
        return `${n}`.replace(/[^0-9.-]/gi, "");
    }, [exchangeOrder?.closeTimestamp, exchangeOrder?.timestamp, innerOrder?.profit, innerOrder?.totalInvest]);

    // 比较当前收益和年化收益
    const profitCompare = useMemo(() => {
        // 套利年化
        const annualized = {
            label: str("mtg_share_grid_profit_percent_annualized"),
            value: `${addPlus(annualizedGridProfit)}${$num(annualizedGridProfit)}%`,
        };
        // 收益率
        const current = {
            label: str("mtg_share_profit_percent"),
            value: `${addPlus(currentProfit)}${$num(currentProfit)}%`,
        };
        const annualizedBigger = new SafeDecimal(currentProfit).lessThan(annualizedGridProfit);

        return {
            lager: annualizedBigger ? annualized : current,
            smaller: annualizedBigger ? current : annualized,
        };
    }, [annualizedGridProfit, currentProfit]);

    const avatar = useImage(`${Constants.avatarLocationOrigin}${data?.avatar}`, `${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);

    // 用户卡片展示信息
    const userInfo = useMemo(() => {
        return {
            avatar,
            title: shareInfo.title,
            subTitle: shareInfo.subTitle,
            info: [
                {
                    label: shareInfo.orderCreateTime.label,
                    value: !shareInfo.orderCreateTime.timeStamp
                        ? "--"
                        : moment(shareInfo.orderCreateTime.timeStamp).format("YYYY/MM/DD HH:mm"),
                },
                shareInfo.marketInfo.openBasePrice,
                shareInfo.marketInfo.currentBasePrice,
            ],
        };
    }, [
        avatar,
        shareInfo.marketInfo.currentBasePrice,
        shareInfo.marketInfo.openBasePrice,
        shareInfo.orderCreateTime,
        shareInfo.subTitle,
        shareInfo.title,
    ]);

    // 收益率卡片展示信息
    const profitInfo = useMemo(() => {
        return {
            title: profitCompare.lager.label,
            profit: profitCompare.lager.value,
            info: [
                {
                    label: profitCompare.smaller.label,
                    value: profitCompare.smaller.value,
                },
                {
                    label: str("mtg_share_running_time"),
                    value: innerOrder?.isConditionAndNotTriggered
                        ? "0"
                        : getLastDesc(
                              innerOrder?.triggerTime && innerOrder?.triggerTime > 0 ? innerOrder?.triggerTime : exchangeOrder?.timestamp,
                              exchangeOrder?.closeTimestamp && exchangeOrder?.closeTimestamp > 0
                                  ? exchangeOrder?.closeTimestamp
                                  : undefined,
                          ),
                },
                {
                    label: shareInfo.totalRoundCount.label,
                    value: `${shareInfo.totalRoundCount.value}`,
                },
                {
                    label: shareInfo.orderCreateTime.label,
                    value: !shareInfo.orderCreateTime.timeStamp
                        ? "--"
                        : moment(shareInfo.orderCreateTime.timeStamp).format("YYYY/MM/DD HH:mm"),
                },
            ],
        };
    }, [
        exchangeOrder?.closeTimestamp,
        exchangeOrder?.timestamp,
        innerOrder?.isConditionAndNotTriggered,
        innerOrder?.triggerTime,
        profitCompare.lager.label,
        profitCompare.lager.value,
        profitCompare.smaller.label,
        profitCompare.smaller.value,
        shareInfo.orderCreateTime.label,
        shareInfo.orderCreateTime.timeStamp,
        shareInfo.totalRoundCount.label,
        shareInfo.totalRoundCount.value,
    ]);

    // 底部邀请码展示信息
    const codeInfo = useMemo(() => {
        return {
            title: str("futures_grid_v2_share_code", { name: data?.nick_name }),
            code: shareCode,
            followUrl: qrUrl,
        };
    }, [data?.nick_name, qrUrl, shareCode]);

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <PionexLogo />
            <Tips text={closedTips.text} visible={closedTips.visible} />
            <UserCard data={userInfo} />
            <ProfitCard data={profitInfo} />
            <div className={`${style.createOrderBtnBase} ${style.createOrderBtn}`} onClick={onCreateOrderProxy}>
                {submitting ? <img src={require("../../images/icon_loading_white.png")} className={style.loadingIcon} /> : null}
                {str("futures_grid_v2_create_order")}
            </div>
            {isPrivate && <div className={style.privateTip}>{str("grid_futures_invited_only_tip", { creator: data?.nick_name })}</div>}
            <CodeCard data={codeInfo} />
            <ModalConfirm ref={(_ref) => (modalRef.current = _ref)} />
        </div>
    );
};
