import { FutureGridV2View } from "../FutureGridV2";
import { useParams } from "react-router-dom";
import { useShareRatioData } from "./useShareRatioData";
import { isMobile } from "src/utils";
import { useEffect, useMemo } from "react";
import style from "./style.module.css";
import { Wrapper } from "src/components/StyleComponent";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ExchangeOrderType } from "TradeAPILib";
import { MTGGridView } from "./MTGGrid";

export const ShareLandingEntry = () => {
    const { share_id } = useParams<{ share_id: string }>();
    const { data, error, isLoading } = useShareRatioData({ id: share_id });
    const mobileDevice = isMobile();
    useEffect(() => {
        document.body.classList.add(style.page);
        document.documentElement.classList.add("theme-dark");
        return () => {
            document.body.classList.remove(style.page);
            document.documentElement.classList.remove("theme-dark");
        };
    }, []);

    if (isLoading || !data) {
        return (
            <Wrapper style={{ marginTop: mobileDevice ? "50%" : "10%" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />} />
            </Wrapper>
        );
    }
    switch (data?.bu_order_type) {
        case ExchangeOrderType.martingale:
            /** 现货马丁 */
            return <MTGGridView data={data} share_id={share_id} />;
        /** 全仓, 合约网格, 现货网格都在一个组件里. 有需求更新的话可以拆分出来 */
        case ExchangeOrderType.gridV4Futures:
        case ExchangeOrderType.gridV4FuturesHedgeGrid:
        case ExchangeOrderType.gridV3:
        case ExchangeOrderType.gridV4:
        case ExchangeOrderType.gridV5:
            return <FutureGridV2View data={data} share_id={share_id} />;
        default:
            return <FutureGridV2View data={data} share_id={share_id} />;
    }
};
