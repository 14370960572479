import { isMobile, queryLang } from "src/utils";
import { NumberFormatter } from "trade_utils_lib";
import style from "./style.module.css";

interface IProfitCard {
    data: {
        title: string;
        profit: string;
        status?: string;
        info: Array<{
            label: string;
            value: string;
        }>;
    };
}

export function ProfitCard(props: IProfitCard) {
    const mobileDevice = isMobile();
    const { title, profit, status, info } = props.data;
    return (
        <div className={style.profitBox}>
            <div className={style.gridProfitBox}>
                <div className={style.gridProfitBoxTitle}>{title}</div>
                {status ? (
                    <div className={style.gridProfitBoxStatus}>{status}</div>
                ) : (
                    <div className={style.gridProfitBoxValue} style={{ color: !profit.startsWith("-") ? "#0ABF7F" : "#F04848" }}>
                        {profit}
                    </div>
                )}
            </div>
            {!mobileDevice ? (
                <div className={style.profitBoxBottom}>
                    {info.map((item, index) => {
                        return (
                            <div key={index} className={style.profitBoxItem}>
                                <div>{item.label}</div>
                                <div>{item.value}</div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={style.profitBoxBottomMobile}>
                    {info.map((item, index) => {
                        return (
                            <div key={index}>
                                {item.label}: {item.value}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
