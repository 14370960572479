import style from "./style.module.css";

export default function Tips({ text, visible }: { text?: string; visible?: boolean }) {
    if (!visible) return null;
    return (
        <div className={style.orderClosedTipsBox}>
            <img className={style.orderClosedTipsIcon} src={require("../../images/tips_new.png")} alt="" />
            <div className={style.orderClosedTipsText}>{text}</div>
        </div>
    );
}
